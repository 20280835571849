:root {
  --primary: #1c1c1c;
  --darkMed: #898989;
  --accent: #e8553a;
  --lightWhite: #ffffff;
}

.typography {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.dark-title-1 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: var(--primary);
}

.dark-title-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary);
}

.dark-title-3 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary);
}

.dark-title-4 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary);
}

.dark-title-5 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary);
}

.dark-title-6 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary);
}

.dark-title-7 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--primary);
}

.dark-title-8 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 16px;
  color: var(--primary);
}

.dark-body-1 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: var(--primary);
  line-height: 32px;
}

.dark-body-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: var(--primary);
  line-height: 24px;
}

.dark-body-3 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--primary);
  line-height: 24px;
}

.dark-body-4 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--primary);
  line-height: 16px;
}

.dark-body-5 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: var(--primary);
  line-height: 16px;
}

.dark-body-6 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  color: var(--primary);
  line-height: 16px;
}

.gray-title-1 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--darkMed);
  line-height: 24px;
}

.gray-title-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: var(--darkMed);
  line-height: 24px;
}

.gray-title-3 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--darkMed);
  line-height: 24px;
}

.gray-body-1 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--darkMed);
  line-height: 24px;
}

.gray-body-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--darkMed);
  line-height: 16px;
}

.gray-body-3 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: var(--darkMed);
  line-height: 16px;
}

.price-title-1 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  color: var(--primary);
  line-height: 40px;
}

.price-title-2 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: var(--primary);
  line-height: 24px;
}

.price-title-3 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--primary);
  line-height: 24px;
}

.price-title-4 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: var(--darkMed);
  line-height: 24px;
}

.price-title-5 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary);
  line-height: 24px;
}

.price-title-6 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary);
  line-height: 24px;
}

.price-title-7 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: var(--darkMed);
  line-height: 16px;
}

.price-title-8 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: var(--primary);
  line-height: 16px;
}

.price-title-9 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  color: var(--darkMed);
  line-height: 16px;
}

.price-title-10 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: var(--darkMed);
  line-height: 16px;
}

.price-title-11 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  color: var(--darkMed);
  line-height: 16px;
}

.accent-body-1 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--accent);
  line-height: 24px;
}

.accent-body-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: var(--accent);
  line-height: 16px;
}

.white-title-1 {
  font-family: "SFProDisplay", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--lightWhite);
  line-height: 24px;
}

.white-title-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--lightWhite);
  line-height: 24px;
}

.white-title-3 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--lightWhite);
  line-height: 24px;
}

.white-title-4 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--lightWhite);
  line-height: 24px;
}

.white-title-5 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  color: var(--lightWhite);
  line-height: 16px;
}

.white-title-6 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  color: var(--lightWhite);
  line-height: 16px;
}

.dark-paragraph-1 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--primary);
  line-height: 24px;
}

.dark-paragraph-2 {
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--primary);
  line-height: 16px;
}
